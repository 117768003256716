import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/Tooltip";
import { Skeleton } from "@/components/ui/skeleton";
import {delay} from "@/utils/general";
import axios from "axios";
import {useState} from "react";

const BACKEND_URL = import.meta.env.VITE_BACKEND_URL;

type GalleryItemMessage = {
  id: string;
  created_at: string;
  prompt: string;
  chat_id: string;
  screenshot?: string; // presigned URL
  // username: string; // not used as it needs a profile table in Supabase
};

type GalleryProps = {
  messages: Array<GalleryItemMessage>;
};

function Gallery({ messages }: GalleryProps) {
  // This lets the messages render in the order they are received
  const [renderedMessages, setRenderedMessages] = useState<Array<GalleryItemMessage>>([]);

  async function getSignedUrl(messageId: string) {
    const url = `${BACKEND_URL}/api/public/chats/messages/${messageId}/screenshot`;
    const headers = {
      "Content-Type": "application/json",
    };

    try {
      const response = await axios.get(url, { headers });

      if (!response.data) {
        return null;
      } else {
        return response.data;
      }
    } catch (error) {
      console.error(error);
    }
  }

  const [retrievedSignedURL, setRetrievedSignedURL] = useState(false);

  async function getSignedUrls() {
    let unsignedMessages = [];

    for (let i = 0; i < messages.length; i++) {
      // Skip if screenshot is already present
      if (messages[i].screenshot) {
        continue; 
      }

      let signedUrl = await getSignedUrl(messages[i].id);

      // If no signed URL, add to unsignedMessages and continue
      if (!signedUrl) {
        unsignedMessages.push(messages[i]);
        continue;
      }

      const newArray = [...messages];
      newArray[i].screenshot = signedUrl;
      messages = newArray;
      setRenderedMessages(messages);
    }

    if (unsignedMessages.length > 0) {
      await delay(1000); // Delay for 1 second

      for (let i = 0; i < unsignedMessages.length; i++) {
        let signedUrl = await getSignedUrl(unsignedMessages[i].id);

        if (!signedUrl) {
          // If still no signed URL, remove from messages
          const newArray = [...messages];
          const index = messages.findIndex((m) => m.id === unsignedMessages[i].id);
          newArray.splice(index, 1);
          messages = newArray;
          continue;
        }

        const newArray = [...messages];

        // Find message index messages where id matches unsignedMessages[i].id
        const index = messages.findIndex((m) => m.id === unsignedMessages[i].id);
        newArray[index].screenshot = signedUrl;
        messages = newArray;
        setRenderedMessages(messages);
      }
    }

    setRetrievedSignedURL(true);
  }

  const [initialized, setInitialized] = useState<boolean>(false);

  if (!initialized && !retrievedSignedURL && messages.length > 0) {
    getSignedUrls();
    setInitialized(true);
  }

  return (
    <>
      {messages.map((message, index) => (
        <span key={index}>
          { message.screenshot ? (
            <GalleryItem message={message} />
          ) : (
            <GallerySkeletonItem />
          )}
        </span>
      ))}
    </>
  );
}

function GallerySkeletonItem() {
  return (
    <div className="mx-auto flex flex-col space-y-3" >
      <Skeleton className="h-[190px] w-[318px] bg-gray-100 rounded-xl" />
      <div className="space-y-2">
        <Skeleton className="h-[32px] w-[320px] bg-gray-100 rounded-md" />
      </div>
    </div>
  );
}

type GalleryItemProps = {
  message: GalleryItemMessage;
};

function GalleryItem({ message }: GalleryItemProps) {
  return (
    <div className="w-full max-w-xs text-center">
      <a
        href={`/render/${message.chat_id}/${message.id}`}
        rel="noopener noreferrer"
        target="_blank"
      >
        <img
          className="border border-1 hover:shadow-lg transition-all object-cover object-center w-full h-48 mx-auto rounded-lg"
          alt="preview"
          src={message.screenshot}
        />
      </a>

      <TooltipProvider delayDuration={400}>
        <Tooltip>
          <TooltipTrigger className="cursor-default">
            <p
              className="mt-2 rounded-lg px-4 py-2 bg-gray-100 text-left"
              style={{
                width: "20rem",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {/* <a className="font-medium text-black"> */}
              {/*   {message.username}: &nbsp; */}
              {/* </a> */}
              <span className="text-gray-700 truncate truncate-ellipsis">
                {message.prompt}
              </span>
            </p>
          </TooltipTrigger>
          <TooltipContent className="bg-white">
            <p>{message.prompt}</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </div>
  );
}

export { Gallery };
